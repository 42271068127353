import { useIsMounted } from '@upvestcz/common/hooks'
import { useFormik } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
    Form,
    FormControl,
    FormLabel,
    Link,
    Stack,
    Button,
    FormErrorMessage,
    Text,
    Input,
} from '@upvestcz/shared-components'
import * as yup from 'yup'
import * as R from 'ramda'
import { UPVEST_EMAIL } from '@upvestcz/common/constants'
import { AUTH0_CONNECTION_NAMES, Auth0Connection } from '@upvestcz/common/auth'
import { CustomError, ERROR_CODES, isCustomErrorLike } from '@upvestcz/common/errors'
import { hasProperty } from '@upvestcz/common/ts-utils'
import { useAfterAuth } from '../lib/auth'
import { useToast } from '../lib/toast'
import { handleRESTError, login } from '../lib/api'
import logger from '../lib/logger'

function LoginForm({ accountLinkToken }: { accountLinkToken?: string }) {
    const { t } = useTranslation()
    const afterAuth = useAfterAuth()
    const addToast = useToast()
    const [isLoading, setIsLoading] = useState(false)
    const isMounted = useIsMounted()

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: yup.object().shape({
            email: yup
                .string()
                .required(t('Vyplňte prosím Váš e-mail'))
                .email(t('Vyplňte prosím platný e-mail')),
            password: yup.string().required(t('Vyplňte prosím Vaše heslo')),
        }),
        async onSubmit(values) {
            setIsLoading(true)

            try {
                const { profile, ...token } = await login({
                    username: values.email,
                    password: values.password,
                    accountLinkToken,
                }).then(R.prop('data'))

                await afterAuth({
                    profile,
                    token,
                })
                // redirect is made in afterAuth
            } catch (err) {
                formik.setFieldValue('password', '')
                formik.setFieldTouched('password', false)
                type IdentityError = { registeredWith: Auth0Connection[] }

                const isIdentityError = (
                    maybeErrorObject: any,
                ): maybeErrorObject is IdentityError =>
                    hasProperty(maybeErrorObject, 'registeredWith')

                const { mainError, responseError } = await handleRESTError<
                    CustomError | IdentityError
                >(err)

                // IIFE for handling the error via early returns.
                ;(() => {
                    if (responseError) {
                        if (responseError.status === 403) {
                            return addToast({
                                type: 'error',
                                message: t('Chybný e-mail nebo heslo'),
                            })
                        }
                        if (responseError.status === 429) {
                            return addToast({
                                type: 'error',
                                message: t(
                                    `Váš účet byl dočasně zablokován kvůli příliš vysokému počtu nesprávného zadání hesla. Kontaktuje prosím podporu Upvest emailem na {{email}}.`,
                                    { email: UPVEST_EMAIL },
                                ),
                                timeout: 20000,
                            })
                        }
                        if (responseError.status === 422 && isIdentityError(responseError.data)) {
                            const { registeredWith } = responseError.data
                            return addToast({
                                type: 'error',
                                message: t(
                                    'Váš účet byl registrován přes {{registeredWith}}, prosím přihlašte se stejným způsobem',
                                    {
                                        registeredWith: registeredWith
                                            .map(connection => AUTH0_CONNECTION_NAMES[connection])
                                            .join(', '),
                                    },
                                ),
                            })
                        }

                        if (isCustomErrorLike(responseError.data)) {
                            switch (responseError.data.code) {
                                case ERROR_CODES.ACCOUNT_CLOSED: {
                                    return addToast({
                                        type: 'error',
                                        message: t(
                                            `Váš účet byl uzavřen. Pokud si přejete svůj účet obnovit, prosím napište nám na {{email}}`,
                                            { email: UPVEST_EMAIL },
                                        ),
                                        timeout: 10_000,
                                    })
                                }
                                default:
                            }
                        }
                    }

                    /*
                        Anything that is not returned early should fall through to here.
                    */

                    logger.error(mainError)
                    addToast({
                        type: 'error',
                        message: t('Při přihlašování se vyskytla neočekávaná chyba'),
                    })
                })()

                setIsLoading(false)
            }
        },
    })

    return (
        <Form data-test-id="form-login" onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <FormControl isInvalid={!!(formik.touched.email && formik.errors.email)}>
                    <FormLabel>{t('E-mail')}</FormLabel>
                    <Input
                        onChange={({ target }) => {
                            formik.setFieldValue('email', target.value)
                        }}
                        value={formik.values.email}
                        type="email"
                        name="email"
                        data-test-id="input-email"
                    />
                    <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!(formik.touched.password && formik.errors.password)}>
                    <FormLabel>{t('Heslo')}</FormLabel>
                    <Input
                        type="password"
                        onChange={({ target }) => {
                            formik.setFieldValue('password', target.value)
                        }}
                        name="password"
                        value={formik.values.password}
                        data-test-id="input-password"
                    />
                    <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
                </FormControl>
                <Text>
                    <Link
                        data-test-id="link-forgotten-password"
                        color="primary"
                        to="/forgotten-password"
                    >
                        {t('Zapomenuté heslo')}
                    </Link>
                </Text>
                <Button
                    type="submit"
                    // Disable the button until the JS is loaded and clicking
                    // the button can be handled correctly. This is due to a problem
                    // with auto form fillers and fast-clicking users.
                    isDisabled={!isMounted}
                    isLoading={isLoading}
                    data-test-id="button-submit"
                >
                    {t('Přihlásit se')}
                </Button>
            </Stack>
        </Form>
    )
}

export default LoginForm
