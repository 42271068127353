import { ReactNode } from 'react'
import { Button, ButtonProps } from '@chakra-ui/react'
import queryString from 'query-string'
import { AUTH0_CONNECTIONS } from '@upvestcz/common/auth'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import * as icons from '../icons'
import Link from '../Link'

type Props = MergeChakraProps<
    {
        children: ReactNode
        relativeRedirectUri: string
    },
    ButtonProps
>

const GoogleAuthButton = ({ children, relativeRedirectUri, ...props }: Props) => {
    return (
        <Button
            {...props}
            data-test-id="google-auth-button"
            as={Link}
            variant="solidWhite"
            leftIcon={<icons.GoogleIcon fontSize="3xl" />}
            w="100%"
            to={`https://${process.env.AUTH0_DOMAIN}/authorize?${queryString.stringify({
                response_type: 'code',
                client_id: process.env.AUTH0_CLIENT_ID,
                connection: AUTH0_CONNECTIONS.GOOGLE,
                audience: `https://${process.env.AUTH0_DOMAIN}/api/v2/`,
                redirect_uri: `${process.env.BASE_URL}${relativeRedirectUri}`,
                scope: 'openid profile email',
                prompt: 'login',
            })}`}
        >
            {children}
        </Button>
    )
}

export default GoogleAuthButton
