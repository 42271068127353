import { useTranslation } from 'react-i18next'
import {
    Flex,
    Link,
    Logo,
    Container,
    ContentContainer,
    Text,
    useBreakpointValue,
    HStack,
    AppLocaleDropdown,
} from '@upvestcz/shared-components'
import { animated, Transition } from 'react-spring'
import { useRouter } from 'next/router'
import { useLocaleLpBaseUrl } from '@upvestcz/common/i18n/use-localized-url'
import React, { Fragment, ReactNode } from 'react'
import { useAuth } from '../../store/auth'
import LogoutCloseButton from '../LogoutCloseButton'

export const HEADER_HEIGHT = '68px'

export const HEADER_BOTTOM_PADDING = { base: '40px', lg: '48px' }

const AnimatedContentContainer = animated<TODO>(ContentContainer)

const AuthLayout = ({
    children,
    announcements = [],
}: {
    children: ReactNode
    announcements?: ReactNode[]
}) => {
    const router = useRouter()
    const isAboveSm = useBreakpointValue({ base: false, sm: true })
    const { t } = useTranslation()
    const lpBaseUrl = useLocaleLpBaseUrl()
    const [auth] = useAuth()

    const items = [
        {
            id: router.route,
            children,
        },
    ] as const

    return (
        <>
            {announcements.map((el, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>{el}</Fragment>
            ))}
            <Flex
                h={HEADER_HEIGHT}
                align={['center', null, 'flex-end']}
                px={[6, 8, 10]}
                justify="space-between"
            >
                <Link to={lpBaseUrl}>
                    <Logo />
                </Link>
                <HStack direction="row" spacing={{ base: 4, lg: 8 }}>
                    <AppLocaleDropdown />
                    {auth.loggedIn && <LogoutCloseButton />}
                </HStack>
            </Flex>

            <Container
                position="relative"
                flexGrow={1}
                pt={HEADER_BOTTOM_PADDING}
                display="flex"
                flexDirection="column"
            >
                <Transition
                    items={items}
                    keys={(item: typeof items[number]) => item.id}
                    initial={{ opacity: 1, transform: 'translate3d(0,0%,0)' }}
                    from={{
                        opacity: 0,
                        transform: 'translate3d(0,-48px,0)',
                        position: 'absolute',
                        width: '100%',
                        right: 0,
                        left: 0,
                        minHeight: '100%',
                    }}
                    enter={{ opacity: 1, transform: 'translate3d(0,0px,0)', position: 'static' }}
                    leave={{
                        opacity: 0,
                        transform: 'translate3d(0,48px,0)',
                        position: 'absolute',
                        width: '100%',
                        right: 0,
                        left: 0,
                        minHeight: '100%',
                    }}
                    trail={isAboveSm ? 250 : 0}
                    immediate={!isAboveSm}
                >
                    {(styles, { children }) => (
                        <AnimatedContentContainer
                            width="100%"
                            flexGrow={1}
                            display="flex"
                            sx={{
                                '> *': {
                                    width: '100%',
                                },
                            }}
                            style={styles}
                        >
                            {children}
                        </AnimatedContentContainer>
                    )}
                </Transition>
            </Container>
            <Text
                maxW="600px"
                pt={12}
                pb={6}
                px={{ base: 6, lg: 0 }}
                mx="auto"
                color="textSecondary"
                textAlign="center"
                fontSize="sm"
            >
                {t(
                    'Pod dohledem ČNB: Společnost upvest s.r.o. je držitelem licence České národní banky – poskytovatele platebních služeb malého rozsahu.',
                )}
            </Text>
        </>
    )
}

export const getAuthLayout = (page: ReactNode, props = {}) => (
    <AuthLayout {...props}>{page}</AuthLayout>
)

export default AuthLayout
