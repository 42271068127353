import React from 'react'
import { Box } from '@chakra-ui/react'

const Form = ({ children, ...props }) => {
    return (
        <Box
            as="form"
            // iOS needs an `action` attribute for nice keyboard
            // https://stackoverflow.com/a/39485162/406725
            action="#"
            // in case the `preventDefault()` fails, prevent
            // exposing email/password in the URL
            method="POST"
            noValidate
            {...props}
        >
            {children}
        </Box>
    )
}

export default Form
