import { CloseButton, CloseButtonProps } from '@upvestcz/shared-components'
import { useLogout } from '../lib/auth'

function LogoutCloseButton(props: CloseButtonProps) {
    const logout = useLogout()

    return <CloseButton size="xl" onClick={() => logout()} {...props} />
}

export default LogoutCloseButton
